import {
  AssetCompanyType,
  AssetOwnershipType,
  AssetShareholderType,
  AssetUsageType,
  CasePurpose,
  CustomerAdditionalIncomeType,
  CustomerEmployeeType,
  JobType,
  LoanGuarantee,
  MaritalStatus,
  MarriageType,
} from "@prisma/client";
import { StringBoolean } from "~/types";
import { CustomerCaseType } from "../../../onboarding/enums";
import { type QuestionOption } from "../../../onboarding/interfaces";
import {
  casePurposeMapping,
  onboardingMaritalStatusMapping,
  onboardingMarriageTypeMapping,
} from "../../../onboarding/mappings";
import { IconHandCoins } from "../../components/atoms/icons/IconHandCoins";
import { IconHouse } from "../../components/atoms/icons/IconHouse";
import { IconHouseLine } from "../../components/atoms/icons/IconHouseLine";
import { IconSociety } from "../../components/atoms/icons/IconSociety";
import { IconUser } from "../../components/atoms/icons/IconUser";
import { IconUserThree } from "../../components/atoms/icons/IconUserThree";
import { isTrialingLabels, jobTypeLabels } from "./mappings";

// CASE
export const casePurposeOptions: QuestionOption[] = [
  {
    label: casePurposeMapping[CasePurpose.purchase_new_asset],
    value: CasePurpose.purchase_new_asset,
  },
  {
    label: casePurposeMapping[CasePurpose.renovate],
    value: CasePurpose.renovate,
  },
  {
    label: casePurposeMapping[CasePurpose.debt_reduction],
    value: CasePurpose.debt_reduction,
  },
  {
    label: casePurposeMapping[CasePurpose.investment],
    value: CasePurpose.investment,
  },
  {
    label: casePurposeMapping[CasePurpose.liquidity],
    value: CasePurpose.liquidity,
  },
];

export const caseTypeOptions: QuestionOption[] = [
  {
    label: "Vous",
    value: CustomerCaseType.Solo,
  },
  {
    label: "Vous et votre conjoint(e)",
    value: CustomerCaseType.Duo,
  },
  {
    label: "Entreprise",
    value: CustomerCaseType.Company,
  },
];

// ASSET
export const assetOwnershipTypeOptions: QuestionOption[] = [
  {
    label: "En propre",
    value: AssetOwnershipType.propre,
    icon: <IconUser width={22} height={22} className="fill-new-dark-3" />,
  },
  {
    label: "En indivision",
    value: AssetOwnershipType.indivision,
    icon: <IconUserThree className="fill-new-dark-3" />,
  },
  {
    label: "En société",
    value: AssetOwnershipType.company,
    icon: <IconSociety className="fill-new-dark-3" />,
  },
];

export const assetUsageTypeOptions: QuestionOption[] = [
  {
    label: "Résidence Principale",
    value: AssetUsageType.main_residence,
    icon: <IconHouseLine className="fill-new-dark-3" />,
  },
  {
    label: "Résidence Secondaire",
    value: AssetUsageType.secondary_residence,
    icon: <IconHouse className="fill-new-dark-3" />,
  },
  {
    label: "Bien locatif",
    value: AssetUsageType.rental,
    icon: <IconHandCoins className="fill-new-dark-3" />,
  },
];

export const assetCompanyTypeOptions: QuestionOption[] = [
  {
    label: "SCI IR",
    value: AssetCompanyType.sci_ir,
  },
  {
    label: "SCI IS",
    value: AssetCompanyType.sci_is,
  },
  {
    label: "SARL IR ",
    value: AssetCompanyType.sarl_ir,
  },
  {
    label: "SARL IS",
    value: AssetCompanyType.sarl_is,
  },
  {
    label: "Autre",
    value: AssetCompanyType.other,
  },
];

export const assetShareholdersTypeOptions: QuestionOption[] = [
  {
    label: "Personne physique",
    value: AssetShareholderType.physical_entity,
  },
  {
    label: "Personne morale",
    value: AssetShareholderType.moral_entity,
  },
];

export const assetLoanGuaranteeOptions: QuestionOption[] = [
  {
    label: "Caution",
    value: LoanGuarantee.caution,
  },
  {
    label: "Hypothèque",
    value: LoanGuarantee.hypotec,
  },
  {
    label: "Nantissement",
    value: LoanGuarantee.collateral,
  },
];

// CUSTOMER
export const maritalStatusOptions: QuestionOption[] = [
  {
    label: onboardingMaritalStatusMapping[MaritalStatus.single],
    value: MaritalStatus.single,
  },
  {
    label: onboardingMaritalStatusMapping[MaritalStatus.married],
    value: MaritalStatus.married,
  },
  {
    label: onboardingMaritalStatusMapping[MaritalStatus.pacs],
    value: MaritalStatus.pacs,
  },
  {
    label: onboardingMaritalStatusMapping[MaritalStatus.cohabitation],
    value: MaritalStatus.cohabitation,
  },
];

export const marriageTypeOptions: QuestionOption[] = [
  {
    label: onboardingMarriageTypeMapping[MarriageType.communaute_legale],
    value: MarriageType.communaute_legale,
  },
  {
    label: onboardingMarriageTypeMapping[MarriageType.separation_de_biens],
    value: MarriageType.separation_de_biens,
  },
  {
    label:
      onboardingMarriageTypeMapping[
        MarriageType.communaute_de_biens_universelle
      ],
    value: MarriageType.communaute_de_biens_universelle,
  },
  {
    label:
      onboardingMarriageTypeMapping[MarriageType.participation_aux_acquets],
    value: MarriageType.participation_aux_acquets,
  },
  {
    label: onboardingMarriageTypeMapping[MarriageType.autre],
    value: MarriageType.autre,
  },
];

export const additionalIncomeOptions: QuestionOption[] = [
  {
    label: "Revenu foncier",
    value: CustomerAdditionalIncomeType.other_rent,
  },
  {
    label: "Produit de placement",
    value: CustomerAdditionalIncomeType.investment,
  },
  {
    label: "Pension",
    value: CustomerAdditionalIncomeType.pension,
  },
  {
    label: "Variable",
    value: CustomerAdditionalIncomeType.variable,
  },
  {
    label: "Allocations CAF",
    value: CustomerAdditionalIncomeType.caf,
  },
];

export const jobTypeOptions: QuestionOption[] = [
  {
    label: jobTypeLabels[JobType.employee],
    value: JobType.employee,
  },
  {
    label: jobTypeLabels[JobType.self_employed],
    value: JobType.self_employed,
  },
  {
    label: jobTypeLabels[JobType.retired],
    value: JobType.retired,
  },
  {
    label: jobTypeLabels[JobType.unemployed],
    value: JobType.unemployed,
  },
];

export const employeeTypeOptions: QuestionOption[] = [
  {
    label: "CDI",
    value: CustomerEmployeeType.CDI,
  },
  {
    label: "CDD",
    value: CustomerEmployeeType.CDD,
  },
];

export const employeeIsTrialingOptions: QuestionOption[] = [
  {
    label: isTrialingLabels[StringBoolean.true],
    value: [StringBoolean.true].toString(),
  },
  {
    label: isTrialingLabels[StringBoolean.false],
    value: [StringBoolean.false].toString(),
  },
];
